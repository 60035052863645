a:hover {
    cursor: pointer;
}

.hasError {
    border: 1px solid red !important;
    outline-color: red !important;
}
.text-danger{
    color:red;
}
#sidebar ul li > a{
    color:white !important;
}
